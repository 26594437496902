const ValidateTicket =  (id: number | undefined, data: object | null):object  => {
  return { method: 'POST', url: `/api/Accounts/${id}/ticketImport/validate`, data: data }; 
};

const ConfirmImport =  (id: number | undefined, data: object | null):object  => {
  return { method: 'POST', url: `/api/Accounts/${id}/ticketImport/confirm`, data: data }; 
};

const GetImports =  (id: number | undefined):object  => {
  return { method: 'GET', url: `/api/Accounts/${id}/ticketImport/get` }; 
};

const GetImportedFile =  (id: number | undefined, fileId: number | undefined):object  => {
  return { method: 'GET', url: `/api/Accounts/${id}/ticketImport/${fileId}/download` }; 
};
    
export {
  ValidateTicket,
  ConfirmImport,
  GetImports,
  GetImportedFile
};
